<template>
  <div class="content">
    <PageHeader title="Whitelabel" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <form @submit.prevent="update">
            <Card title="Configurar" footer-class="text-right">
              <div class="row">
                <div class="col-4">
                  <FormGroup label="Dominio">
                    <FormInput v-model="form.domain" />
                  </FormGroup>
                </div>
                <div class="col-4">
                  <FormGroup label="Logo">
                    <FormFile v-model="form.logo" />
                  </FormGroup>
                </div>
                <div class="col-4">
                  <FormGroup label="Cor">
                    <FormColor v-model="form.color" placeholder="#000000" />
                  </FormGroup>
                </div>
              </div>
              <template #footer>
                <div class="form-actions">
                  <div class="row">
                    <div class="col-md-12">
                      <Button type="submit" :loading="loading"> Salvar </Button>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Card from '@/components/rebranding/card/Card.vue';
import FormGroup from '@/components/rebranding/form/FormGroup.vue';
import FormInput from '@/components/rebranding/form/FormInput.vue';
import FormColor from '@/components/rebranding/form/FormColor.vue';
import FormFile from '@/components/rebranding/form/FormFile.vue';
import Button from '@/components/rebranding/button/Button.vue';

export default {
  name: 'AdminAnalytics',
  components: {
    PageHeader,
    Card,
    FormGroup,
    FormInput,
    FormColor,
    FormFile,
    Button,
  },
  data() {
    return {
      loading: false,
      form: {
        domain: this.$store.state.admin.whitelabel.domain,
        logo: this.$store.state.admin.whitelabel.logo,
        color: this.$store.state.admin.whitelabel.color,
      },
    };
  },
  mounted() {
    console.log('whitelabel', this.$store.state.admin);
  },
  methods: {
    update() {
      this.loading = true;
      this.$store
        .dispatch('updateWhitelabel', this.form)
        .then(
          (response) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Atualização com sucesso.',
              type: 'success',
            });
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}
</style>
