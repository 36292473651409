<template>
  <InputGroup>
    <FormInput
      :placeholder="placeholder"
      v-model="content"
      @input="handleInput"
      @change="handleInput"
    />
    <color-picker
      v-if="isShowing"
      :value="'#752DE6'"
      :disableAlpha="true"
      @input="handlePicker"
    />
    <template #append>
      <div class="input-group-append">
        <Button class="btn btn-primary" type="button" @click="show">
          <span class="material-icons"> colorize </span>
        </Button>
      </div>
    </template>
  </InputGroup>
</template>

<script>
import InputGroup from '@/components/rebranding/form/InputGroup.vue';
import FormInput from '@/components/rebranding/form/FormInput.vue';
import Button from '@/components/rebranding/button/Button.vue';

export default {
  name: 'FormColor',
  props: ['value', 'placeholder'],
  components: {
    InputGroup,
    FormInput,
    Button,
  },
  data() {
    return {
      isShowing: false,
      content: this.value,
    };
  },
  methods: {
    show() {
      this.isShowing = !this.isShowing;
    },
    handleInput(e) {
      this.$store.state.admin.whitelabel.color = this.content;
      this.$emit('input', this.content);
    },
    handlePicker(color) {
      this.content = color.hex;
      this.handleInput(color);
    },
  },
};
</script>
